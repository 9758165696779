<script setup lang="ts">
const { locale, defaultLocale }: any = useI18n()

const selectedIcon = computed(() => `/${locale.value}.svg`)

const localeItems = [
  [{
    label: 'English',
    avatar: {
      src: `/en.svg`,
    },
    click: async () => {
      await navigateTo(defaultLocale === 'en' ? '/' : '/en', { external: true })
    },
  }, {
    label: 'Tiếng Việt',
    avatar: {
      src: '/vi.svg',
    },
    click: async () => {
      await navigateTo(defaultLocale === 'vi' ? '/' : '/vi', { external: true })
    },
  }],
]
</script>

<template>
  <UDropdown
    :items="localeItems"
    :ui="{ item: { disabled: 'cursor-text select-text', padding: 'px-1.5 py-2', size: 'text-base' } }"
    :popper="{ placement: 'bottom-start' }"
    class="pt-3"
  >
    <UAvatar
      :src="selectedIcon"
      size="xs"
    />
  </UDropdown>
</template>
